<template>
    <div>
        <ContingentLiabilities
            v-if="cardData.contingentLiabilities != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.contingentLiabilities.headTitle"
            :toggle-value="cardData.contingentLiabilities.add"
            @toggle-change="handleToggleChange"
            :contingent-liabilities-data="cardData.contingentLiabilities"
        />
        <PlacedSecurities
            v-if="cardData.placedSecurities != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.placedSecurities.headTitle"
            :toggle-value="cardData.placedSecurities.add"
            @toggle-change="handleToggleChange"
            :placed-securities-data="cardData.placedSecurities"
        />
        <SignificantEventsAfterYearEnd
            v-if="cardData.significantEventsAfterYearEnds != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.significantEventsAfterYearEnds.headTitle"
            :toggle-value="cardData.significantEventsAfterYearEnds.add"
            @toggle-change="handleToggleChange"
            :data="cardData.significantEventsAfterYearEnds"
        />
        <!-- @update:text="cardData.significantEventsAfterYearEnds.text = $event" -->
        <AverageNumberOfEmployees
            v-if="cardData.averageNumberOfEmployees != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.averageNumberOfEmployees.headTitle"
            :toggle-value="cardData.averageNumberOfEmployees.add"
            @toggle-change="handleToggleChange"
            :average-number-of-employees-data="cardData.averageNumberOfEmployees"
        />
        <EquipmentToolsAndInstallations
            v-if="cardData.equipmentToolsAndInstallations != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.equipmentToolsAndInstallations.headTitle"
            :toggle-value="cardData.equipmentToolsAndInstallations.add"
            @toggle-change="handleToggleChange"
            :data="cardData.equipmentToolsAndInstallations"
        />
        <ImprovementExpensesOtherProperty
            v-if="cardData.improvementExpensesOtherProperties != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.improvementExpensesOtherProperties.headTitle"
            :toggle-value="cardData.improvementExpensesOtherProperties.add"
            @toggle-change="handleToggleChange"
            :data="cardData.improvementExpensesOtherProperties"
        />
        <OtherLongTermReceivables
            v-if="cardData.otherLongTermReceivables != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.otherLongTermReceivables.headTitle"
            :toggle-value="cardData.otherLongTermReceivables.add"
            @toggle-change="handleToggleChange"
            :data="cardData.otherLongTermReceivables"
        />
        <LongTermLiabilities
            v-if="cardData.longTermLiabilities != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.longTermLiabilities.headTitle"
            :toggle-value="cardData.longTermLiabilities.add"
            @toggle-change="handleToggleChange"
            :data="cardData.longTermLiabilities"
        />
        <CheckAccountCredit
            v-if="cardData.checkAccountCredits != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.checkAccountCredits.headTitle"
            :toggle-value="cardData.checkAccountCredits.add"
            @toggle-change="handleToggleChange"
            :data="cardData.checkAccountCredits"
        />
        <div v-if="cardData.newCards != null">
            <NewCard
                @saveData="saveData()"
                @close="closeCard"
                @delete="removeCard(index)"
                :title="cardData.newCards.headTitle"
                :toggle-value="true"
                @toggle-change="handleToggleChange"
                :input-text="cardData.newCards"
                @update:text="cardData.newCards = $event"
                @move-up="moveUp(index)"
                @move-down="moveDown(index)"
                :input-disabled="inputDisabled"
            />
        </div>
        <div v-if="isLastIndex" class="flex flex-row pt-8">
            <el-form>
                <el-form-item class="pr-2">
                    <el-button size="medium" type="primary" @click="addCard()">Lägg till not</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        ContingentLiabilities: () => import("./ContingentLiabilities.vue"),
        PlacedSecurities: () => import("./PlacedSecurities.vue"),
        SignificantEventsAfterYearEnd: () => import("./SignificantEventsAfterYearEnd.vue"),
        AverageNumberOfEmployees: () => import("./AverageNumberOfEmployees.vue"),
        EquipmentToolsAndInstallations: () => import("./EquipmentToolsAndInstallations.vue"),
        ImprovementExpensesOtherProperty: () => import("./ImprovementExpensesOtherProperty.vue"),
        OtherLongTermReceivables: () => import("./OtherLongTermReceivables.vue"),
        LongTermLiabilities: () => import("./LongTermLiabilities.vue"),
        CheckAccountCredit: () => import("./CheckAccountCredit.vue"),
        NewCard: () => import("../../accountingPrinciples/components/NewCard.vue"),
    },
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
        cardData: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        lastIndex: {
            type: Number,
        },
        notesData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            inputDisabled: true,
        };
    },
    methods: {
        async saveData() {
            this.$emit("saveData");
        },
        async closeCard(value) {
            this.$emit("close", value);
        },
        async handleToggleChange(eventData) {
            if (eventData.source === 1) {
                this.cardData.contingentLiabilities.add = eventData.value;
            }
            if (eventData.source === 2) {
                this.cardData.placedSecurities.add = eventData.value;
            }
            if (eventData.source === 3) {
                this.cardData.significantEventsAfterYearEnds.add = eventData.value;
            }
            if (eventData.source === 4) {
                this.cardData.averageNumberOfEmployees.add = eventData.value;
            }
            if (eventData.source === 5) {
                this.cardData.equipmentToolsAndInstallations.add = eventData.value;
            }
            if (eventData.source === 6) {
                this.cardData.improvementExpensesOtherProperties.add = eventData.value;
            }
            if (eventData.source === 7) {
                this.cardData.otherLongTermReceivables.add = eventData.value;
            }
            if (eventData.source === 8) {
                this.cardData.longTermLiabilities.add = eventData.value;
            }
            if (eventData.source === 9) {
                this.cardData.checkAccountCredits.add = eventData.value;
            }
            this.$emit("saveData");
        },
        addCard() {
            this.notesData.cards.push({
                newCards: {
                    headTitle: "",
                    text: "",
                    addTable: false,
                    tableRows: [
                        {
                            columnOne: "",
                            columnTwo: "",
                        },
                        {
                            columnOne: "",
                            columnTwo: "",
                        },
                    ],
                },
            });
            this.$emit("saveData");
        },
        removeCard(index) {
            this.notesData.cards.splice(index, 1);
        },
        moveUp(index) {
            this.$emit("move-up", { index: index });
        },
        moveDown(index) {
            this.$emit("move-down", { index: index });
        },
    },
    computed: {
        isLastIndex() {
            return this.index === this.lastIndex - 1;
        },
    },
};
</script>
